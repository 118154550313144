import { useState, useEffect } from 'react';
import StackTrace from 'stacktrace-js';
import { buildLogger, LoggableError } from 'logger';

import { hasSubscription } from 'state/selectors/has-subscription';
import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

export default () => {
  const logger = buildLogger();
  const [hasSub, setHasSub] = useState(false);
  const [subscription, setSubscription] = useState({});
  const [getSubLoading, setHookLoading] = useState(true);

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  useEffect(() => {
    const getSubriptions = async () => {
      try {
        const sub = await hasSubscription();
        setHasSub(sub);
        if (sub) {
          const getGetSubscriptionService = await import('services/subscription/get-server-subscription-service');
          const { getSubscription } = getGetSubscriptionService.default({
            stateContext,
            noticationContext,
          });
          const foundSubscription = await getSubscription();
          setSubscription(foundSubscription);
        }
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'getSubriptions:onError' },
          message,
          trace,
        }));
      } finally {
        setHookLoading(false);
      }
    };

    getSubriptions();

    // eslint-disable-next-line
  }, []);

  return { hasSub, subscription, getSubLoading };
};
