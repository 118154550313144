import { useState, useEffect } from 'react';
import StackTrace from 'stacktrace-js';
import { buildLogger, LoggableError } from 'logger';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

export default () => {
  const logger = buildLogger();
  const [pricingModels, setPricingModels] = useState({});
  const [getPricingLoading, setHookLoading] = useState(true);

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  useEffect(() => {
    const getPricingModels = async () => {
      try {
        const getPricingService = await import('services/pricing-service');
        const { getPricing } = getPricingService.default({
          stateContext,
          noticationContext,
        });
        const foundPricing = await getPricing();
        setPricingModels(foundPricing);
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'getPricing:onError' },
          message,
          trace,
        }));
      } finally {
        setHookLoading(false);
      }
    };

    getPricingModels();

    // eslint-disable-next-line
  }, []);

  return { pricingModels, getPricingLoading };
};
